<script setup lang="ts">
import { Icon } from "@/components/Ui";
import { onMounted } from "vue";
import type {TButtonColor} from "@/components/Ui/Button/button.type";

interface Props {
  title?: string;
  color?: TButtonColor;
  size?: "default" | "large" | "small" | "custom";
  rounded?: "default" | "large" | "small" | "full" | "custom";
  icon?: string;
  iconPos?: "left" | "right";
  iconClass?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  color: "dark",
  size: "default",
  rounded: "default",
  icon: "",
  iconPos: "right",
  iconClass: "",
});

const isPending = ref<boolean>(false);
const onClickHandler = () => {
  isPending.value = !isPending.value;

  setTimeout(() => {
    isPending.value = !isPending.value;
  }, 3000);
};
onMounted(() => {
  isPending.value = false;
});
</script>

<template>
  <button
    :class="{
      [$style.root]: true,
      [$style.root__disabled]: props.disabled,
      [$style[`root__color-${props.color}`]]: props.color !== 'custom',
      [$style[`root__size-${props.size}`]]: props.size !== 'custom',
      [$style[`root__rounded-${props.rounded}`]]: props.rounded !== 'custom',
    }"
    :disabled="props.disabled || isPending"
    @click="onClickHandler"
  >
    <template v-if="!$slots.default">
      <template v-if="props.title">
        <span :class="$style.title">
          {{ props.title }}
        </span>
      </template>

      <template v-if="props.icon">
        <Icon
          :name="props.icon"
          :class="{
            [props.iconClass]: props.iconClass,
            [$style[`root__size-${props.size}--icon`]]: !props.iconClass,
            [$style[`iconPos-${props.iconPos}`]]: props.iconPos,
          }"
        />
      </template>
    </template>

    <slot />
  </button>
</template>

<style lang="scss" module>
.root {
  @apply flex items-center justify-center gap-2 transition-all;

  &:disabled {
    @apply opacity-50;
  }

  &:hover:enabled {
    @apply opacity-90;
  }

  // color
  &__color {
    &-dark {
      @apply bg-neutral-900 text-white;
      @apply border-[1.25px] border-solid border-neutral-900;
    }

    &-white {
      @apply bg-white text-neutral-900;
      @apply border-[1.25px] border-solid border-white;
    }

    &-blue {
      @apply bg-accent-base text-white;
      @apply border-[1.25px] border-solid bg-accent-base;
    }

    &-orange {
      @apply bg-accent-primary text-white;
      @apply border-[1.25px] border-solid border-accent-primary;
    }

    &-transparent {
      @apply bg-transparent hover:opacity-80 border-[1.25px] border-solid border-neutral-900;
      @apply dark:border-white;
    }
    &-transparent-dark {
      @apply bg-transparent hover:opacity-80 border-[1.25px] border-solid border-white text-white;
    }

    &-grey {
      @apply bg-neutral-200 text-neutral-900 hover:opacity-80 border-[1.25px] border-solid border-neutral-200;
    }
    &-light-grey {
      @apply bg-neutral-100 text-neutral-900 hover:opacity-80 border-[1.25px] border-solid border-neutral-100;
    }

    &-dark-grey {
      @apply bg-neutral-600 text-white hover:opacity-80 border-[1.25px] border-solid border-neutral-600;
    }
  }

  // size
  &__size {
    &-default {
      @apply px-6 py-[18px] text-xl;

      & svg {
        @apply h-5 w-5 min-w-[22px];
      }
    }

    &-small {
      @apply py-3 px-6 text-base;
      & svg {
        @apply h-5 w-5 min-w-[20px];
      }
    }

    &-large {
      @apply px-5 py-5;

      & svg {
        @apply h-[22px] w-[22px] min-w-[22px];
      }
    }
  }

  // rounded
  &__rounded {
    &-default {
      @apply rounded-12;
    }

    &-large {
      @apply rounded-26;
    }

    &-full {
      @apply rounded-full;
    }
  }
}

.title {
  @apply order-2;
}

.iconPos {
  &-right {
    @apply order-3;
  }

  &-left {
    @apply order-1;
  }
}
</style>
